@media only screen and (max-width: 767px) {
  body {
    padding-top:65px;
  }
  .navbar-default {
    border:0;
    margin-bottom:0;
    .navbar-header {
      button {
        background: transparent;
        border: 0;
        float: right;
        width: 50px;
        height: 35px;
        display: block;
        text-align: center;
        position: relative;
        margin: 18px 30px 12px 0;
        cursor: pointer;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        span {
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background: white;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;
        }
        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2) {
          top: 12px;
        }

        span:nth-child(3) {
          top: 24px;
        }
      }
      .menu-toggle.open span:nth-child(1) {
        top: 12px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      .menu-toggle.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      .menu-toggle.open span:nth-child(3) {
        top: 12px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    .container {
      #navbar {
        border:0;
        .navbar-nav {
          padding-top:0;
          margin:0 -15px;
          background:$greyLight;
          li {
            border-bottom:1px solid white;
            a {
              text-align:center;
              font-size:1.5em;
              padding:15px 0;
              text-transform:uppercase;
            }
            &.active {
              a {
                background:white;
                color:$orange;
              }
            }
            &:last-child {
              border-bottom:0;
            }
          }
        }
      }
    }
  }
  .grey-block,
  .white-block {
    .title {
      padding:10px 30px 15px 30px;
      h1 {
        font-size:1.8em;
      }
    }
    .title-h2 {
      &:after {
        left:30px;
      }
    }
    .title-h2 h2 {
      font-size:1.6em;
    }
    .text {
      padding:30px 30px 30px 30px;
    }
  }
  .text-block {
    padding:0;
  }
  #nvm {
    .v-container {
      .v-inner {
        .content {
          padding:30px;
          .logo {
            margin:0 auto 15px auto;
          }
        }
      }
    }
    .short-form {
      .form-container {
        .form-inner {
          .btn-primary-dark {
            max-width:100%;
          }
        }
      }
    }
  }
  #hoe {
    .v-container {
      .v-inner {
        .content {
          padding:40px 30px;
          text-align:center;
        }
      }
    }
  }
  .form-group {
    margin-bottom:5px;
    .btn-primary {
      width:100%;
    }
    &:last-of-type {
      margin-bottom:5px;
    }
  }
  .form-group .col-md-4:first-child,
  .form-group .col-md-8:first-child {
    padding-right:0;
    margin-bottom:5px;
  }
  .form-group .col-md-4 {
    margin-bottom:5px;
  }
  .form {
    .checkbox {
      float:left;
      width:100%;
      label {
        float:left;
        width:100%;
        margin-bottom:20px;
      }
    }
  }

  #onderdelen {
    ul {
      margin:30px 0 0 0;
      padding:0;
      width:100%;
      float:left;
      li {
        border-bottom:1px solid #eee;
        width:100%;
        min-height:10px;
        padding:30px 30px 30px 75px;
        &:before {
          left:30px;
        }
      }
    }
  }
  #referenties {
    .v-container,
    .v-inner {
      .referentie {
        padding:40px 30px;
      }
    }
  }
  footer {
    border-top:1px solid #eee;
    .v-container {
      .v-inner {
        ul.links {
          float: left;
          margin: 20px 0 15px 0;
          padding: 0;
          list-style: none;
          width: 50%;
          li {
            width:100%;
            font-size:13px;
            float:right;
            text-align:right;
            margin-right:5px;
          }
        }
        ul.logos {
          float: right;
          margin: 0;
          padding: 0;
          list-style: none;
          width: 50%;
          li {
            float:left;
            margin:25px 30px 0px 5px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  header {
    height:auto;
    #slide {
      min-height:105px !important;
      height:auto !important;
    }
  }
  #nvm {
    .v-container {
      .v-inner {
        .content {
          p {
            font-size:1.5em;
            line-height:30px;
          }
        }
      }
    }
    .short-form {
      .form-container {
        max-width:90%;
        margin:0 auto 30px auto;
        margin-left:5%;
        h2 {
          font-size:1.8em;
          margin:0 0 5px 0;
        }
        .form-small {
          .checkbox {
            label {
              padding-top:0;
            }
          }
        }
      }
    }
  }
}