.steps {
  background:$greyLight;
  width:100%;
  float:left;
  padding:60px 0 0 0;
  .step {
    .number {
      width:110px;
      height:110px;
      background:$orange;
      color:white;
      border-radius:50%;
      display:block;
      text-align:center;
      padding:22px 0 0 0;
      margin:0 auto;
      span {
        font-family: $fontAcumin;
        text-transform: uppercase;
        display:block;
        font-size:20px;
        font-weight:100;
        font-style:normal;
        line-height:1;
        &:last-child {
          font-size:55px;
          font-family: $fontChanga;
        }
      }
    }
    .desc {
      text-align:center;
      margin:30px auto 0 auto;
      padding:0 15px;
      p {
        margin:0;
      }
    }
  }
}