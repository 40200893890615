.btn-primary,
.btn-primary-dark,
.btn-secondary {
  border-radius:5px;
  padding:16px 25px 18px 25px;
  border:0;
  font-family: $fontAcumin;
  font-weight:400;
  font-style:normal;
  text-transform: uppercase;
  font-size: 18px;
}



.btn-primary {
  background:$orange;
  color:white;
  border-bottom:3px solid $orangeDark;
  &:hover,
  &:focus {
    background:$orangeDark;
    border-bottom:3px solid $orangeBorder;
  }
}
.btn-primary-dark {
  background:$orangeDark;
  color:white;
  border-bottom:3px solid $orangeBorder;
  &:hover,
  &:focus {
    background:$green;
    border-bottom:3px solid $greenBorder;
  }
}
.btn-secondary {
  background:$green;
  color:white;
  border-bottom:3px solid $greenBorder;
  &:hover,
  &:focus {
    background:$orange;
    border-bottom:3px solid $orangeBorder;
    color:$orangeBorder;
  }
}