@media only screen and (max-width: 991px) {
  header {
    height:210px;
    #slide {
      height:210px !important;
      min-height:210px !important;
    }
  }

  #nvm {
    height:auto;
    .v-container {
      .v-inner {
        .content {
          padding:30px 60px;
          max-width:100%;
          .logo {
            img {
              max-width:60px;
            }
          }
        }
      }
    }
    .short-form {
      position:relative;
      width:100%;
      padding-bottom:0;
      .form-container {
        min-width:90%;
        margin:20px auto 60px auto;
        margin-left:5%;
        display:block;
        .form-inner {
          .btn-primary-dark {
            max-width:275px;
            margin:0 auto;
          }
        }
      }
    }
  }
  #hoe {
    height: auto;
    background: $greyLight;
    .v-container {
      background: $greyLight;
      .v-inner {
        .content {
          max-width:100%;
        }
      }
    }
  }
  #onderdelen {
    ul {
      li {
        width:50%;
        min-height:200px;
      }
    }
  }
  #referenties {
    background:$greyLight;
    .v-container {
      .v-inner {
        .referentie {
          background:$greyLight;
          max-width:100%;
          .image {
            width:150px;
            height:150px;
            display:block;
            border-radius:50%;
            margin:0 auto 30px auto;
            overflow:hidden;
            border:5px solid $greyLight;
            box-shadow: 0 0 0 1pt white;
            position:relative;
            img {
              position:absolute;
              right:0;
              bottom:0;
              max-height:150px;
            }
          }
        }
      }
    }

  }

}