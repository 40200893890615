html, body {
  margin: 0;
  padding:0;
  font-size:100%;
}
html {
  height:100%;
}
body {
  margin: 0;
  padding:0;
  font-family: $fontAcumin;
  font-size:100%;
  height: 100%;
  min-height:100%;
  overflow-x:hidden;
}
a {
  color:$orange;
  &:hover,
  &:focus {
    outline:0;
    color:$orange;
  }
}
select:-moz-focusring {
  color:transparent;
  text-shadow:0 0 0 #000;
}
select:-moz-focusring * {
  color:#000;
  text-shadow:none;
}

p, ul, li, ol {
  color:$grey;
  line-height:1.8em;
  font-family: $fontAcumin;
  font-weight:300;
}
h2 {
  line-height:1.3em;
  font-size:2.6em;
  font-weight:100;
  font-style: normal;
}
section {
  float:left;
  width:100%;
  position:relative;
}
.v-container {
  display: table;
  width:100%;
  vertical-align: middle;
  position:relative;
  .v-inner {
    display: table-cell;
    vertical-align: middle;
    position:relative;
    .content {
      padding:0 100px;
    }
  }
}
.text-block {
  background:$greyLight;
  width:100%;
  float:left;
  padding:60px 0 60px 0;
}
.grey-block,
.white-block {
  background:white;
  width:100%;
  max-width:1300px;
  margin:0 auto 0 auto;
  padding:0;
  position:relative;
  .title,
  .title-h2 {
    padding:18px 50px 24px 50px;
    background:$orange;
    position:relative;
    h1, h2 {
      color:white;
      margin:0;
      padding:0;
      font-weight:100;
      font-style:normal;
      font-size:2em;
    }
    h1 {
      font-size:2.6em
    }
    &:after {
      content:"\f0d7";
      font-family: FontAwesome;
      position:absolute;
      bottom:-12px;
      left:50px;
      font-size:40px;
      width:30px;
      height:20px;
      line-height:20px;
      color:$orange;
    }
  }
  .title-h2 {
    padding:15px 50px 20px 50px;
    background:#bbb;
    &:after {
      color:#bbb;
    }
  }
  .text {
    padding:50px 50px 30px 50px;
    h2, h3 {
      font-size:1.8em;
      font-weight:400;
      margin:0 0 3px;
      padding:0;
      color:$orange;
    }
    h3 {
      font-size:1.4em;
    }
    p {
      margin:0 0 30px 0;
    }
    ul {
      margin:0 0 30px 0;
      padding:0 0 0 18px;
      li {
        strong {
          color:$orange;
        }
      }
    }
    .alert {
      ul {
        margin:0;
      }
    }
  }
}
.grey-block {
  background:#f6f6f6;
}
