$orange:                #EC661A;
$orangeDark:            #bd4800;
$orangeBorder:          #a53f00;
$green:                 #7a9f3d;
$greenBorder:           #5d8618;
$grey:                  #676767;
$greyFont:              #9a9a9a;
$greyLight:             #eeeeee;

$fontAcumin:            "acumin-pro", Arial, sans-serif;
$fontChanga:            'Changa One', cursive;