@media only screen and (max-width: 1199px) {
  body {
    padding-top:53px;
  }
  .navbar-fixed-top {
    background:$orange;
  }
  header {
    height:310px;
    #slide {
      height:310px !important;
      min-height:310px !important;
    }
  }
  #hoe {
    height: auto;
    background: white url('/images/tilburg.jpg') no-repeat center right;
  }

  #onderdelen {
    ul {
      li {
        padding:30px 40px 0 40px;
        &:before {
          left:0;
          top:32px;
          font-size:30px;
        }
      }
    }
  }
  #referenties {
    background:#ebf0f4 url("/images/happy_couple.jpg") no-repeat 200px -50px;
    background-size:100%;
    height:auto;
    .v-container,
    .v-inner {
      .referentie {
        max-width:50%;
        padding:80px 60px;
        p {
          font-size:1.25em;
        }
      }
    }
  }
  #nvm {
    .short-form {
      .form-container {
        min-width:375px;
        max-width:375px;
        .form-inner {
          padding:20px;
        }
      }
    }
    .v-container {
      .v-inner {
        .content {
          padding:30px 60px;
          .logo {
            margin:0 auto 30px auto;
            display:block;
            width:100%;
            max-width:100%;
            img {
              max-width:95px;
              margin:0 auto;
            }
          }
          .text {
            float:left;
            width:100%;
            text-align:center;
          }
          p {
            font-size:1.75em;
            line-height:40px;
          }
        }
      }
    }
  }
  .steps {
    padding-top:30px;
    padding-bottom:60px;
    .step {
      margin-top:30px;
    }
  }
}