header {
  width:100%;
  height:450px;
  position:relative;
  float:left;
  background:black;
  .logo {
    max-width:150px;
    img {
      max-width:100%;
      display:block;
    }
  }
  &:after {
    content:"";
    width:100%;
    position:absolute;
    bottom:-15px;
    left:0;
    right:0;
    box-shadow: 0 -5px 5px -5px rgba(0,0,0,0.3);
    height:15px;
  }
}