@media only screen and (min-width : 768px) {
  .menu-toggle {
    display: none;
  }
  .navbar-fixed-top {
    background:transparent;
    border:0 !important;
    transition: ease all 0.3s;
    #navbar {
      .navbar-right {
        background:$orange;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        li {
          a {
            color:white;
            border-right:1px solid $orangeDark;
            font-size:1.125em;
            font-weight:300;
            font-style:normal;
            line-height:1em;
            text-transform: lowercase;
            padding:16px 30px 20px 30px;
            &:hover,
            &:focus {
              background:$orangeDark;
            }
          }
          &.active {
            a {
              background:$orangeDark;
            }
          }
          &:first-child {
            a {
              border-bottom-left-radius: 5px;
            }
          }
          &:last-child {
            a {
              border-right:0;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
    &.fixed {
      background:$orange;
      #navbar {
        .navbar-right {
          li {
            &:first-child,
            &:last-child {
              a {
                border-radius:0;
              }
            }
          }
        }
      }
    }
  }
}