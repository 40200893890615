.form-group {
  width: 100%;
  float: left;
  position: relative;
  input.form-control,
  textarea.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) inset;
    height: 55px;
    font-family: $fontAcumin;
    font-weight: 100;
    font-style: normal;
    font-size: 16px;
    padding: 3px 18px 6px 18px;
    -webkit-transition: all ease-in-out .15s;
    -o-transition: all ease-in-out .15s;
    transition: all ease-in-out .15s;
    &:focus,
    &:active {
      border: 1px solid $orange;
      box-shadow: none;
    }
  }
  select.form-control {
    height:55px;
    font-family: $fontAcumin;
    font-weight:100;
    font-style:normal;
    font-size:16px;
    color:#c1c1c1;
    padding-top:3px;
    &:focus {
      box-shadow: none;
      border: 1px solid $orange;
    }
  }
  textarea.form-control {
    height:195px;
    padding-top:12px;
    resize:none;
  }
  .col-md-4,
  .col-md-8 {
    padding: 0;
    &:first-child {
      padding-right: 15px;
    }
  }
  .col-md-12 {
    padding: 0;
  }
  /*
  &:last-of-type {
    margin-bottom: 0;
  }
  */
}

.checkbox {
  float:left;
  width:100%;
  label {
    font-weight: 100;
    font-style: normal;
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
#form_full {
  .checkbox {
    label {
      padding-left:5px;
    }
  }
}
.form {
  width: 100%;

  .checkbox {
    label {
      height: 55px;
      padding-top: 4px;
      a {
        color:$grey;
      }
    }
  }
}
.form-small {
  .btn-primary-dark {
    margin-top:30px;
    &:hover,
    &:focus {
      background:white;
      border-bottom:3px solid #ccc;
      color:$orange;
    }
  }
}

.alert-danger {
  border:1px solid #DF1938;
  background:#F46E83;
  ul {
    margin:0;
    padding:0 0 0 20px;
  }
  li {
    color:#8C0016 !important;
    font-family: $fontAcumin;
  }
}

.form-control::-webkit-input-placeholder {
  color: #c1c1c1;
}

.form-control:focus::-webkit-input-placeholder {
  color: $orange;
}

.form-control::-moz-placeholder {
  color: #c1c1c1;
}

.form-control:focus::-moz-placeholder {
  color: $orange;
}

.form-control:-ms-input-placeholder {
  color: #c1c1c1;
}

.form-control:focus:-ms-input-placeholder {
  color: $orange;
}

.form-control:-moz-placeholder {
  color: #c1c1c1;
}

.form-control:focus:-moz-placeholder {
  color: $orange;
}