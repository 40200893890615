
@media only screen and (max-width: 1399px) {
  header {
    height:380px;
    #slide {
      height:380px !important;
      min-height:380px !important;
    }
  }
  h2 {
    font-size:2em;
  }

  .btn-primary,
  .btn-primary-dark,
  .btn-secondary {
    padding:12px 20px;
    font-size:16px;
  }

  #nvm {
    .v-container {
      .v-inner {
        .content {
          max-width: 60%;
          padding:60px;
          .logo {
            max-width:75px;
            margin-right:45px;
            margin-top:15px;
          }
          p {
            font-size:2em;
          }
        }
      }
    }
  }
  #hoe {
    height: auto;
    background: white url('/images/tilburg.jpg') no-repeat center right;
    background-size:100%;
    .v-container {
      height:auto;
      background-size:90%;
      .v-inner {
        height:auto;
        .content {
          padding:80px 60px;
          max-width:50%;
        }
      }
    }
  }
  #onderdelen {
    ul {
      li {
        padding:30px 60px 0 60px;
        &:before {
          left:0;
          top:32px;
          font-size:30px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  #nvm {
    .v-container {
      .v-inner {
        .content {
          padding:0 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1599px) {
  header {
    height: 500px;
    #slide {
      height: 500px !important;
      min-height: 500px !important;
    }
  }
}
@media only screen and (min-width: 1767px) {
  header {
    height: 560px;
    #slide {
      height: 560px !important;
      min-height: 560px !important;
    }
  }
  #nvm {
    .v-container {
      .v-inner {
        .content {
          max-width:55%;
        }
      }
    }
  }
}
