#nvm {
  height:450px;
  width:100%;
  float:left;
  position:relative;
  .v-container {
    height:100%;
    position:relative;
    .v-inner {
      height:100%;
      position:relative;
      .content {
        max-width: 60%;
        float: left;
        p {
          font-size: 2.6em;
          line-height: 60px;
          font-weight: 100;
          font-style: normal;
          color: $orange;
          margin: 0;
          padding: 0;
        }
        .logo {
          float: left;
          margin-right: 60px;
          margin-top:16px;
        }
        .text {
          overflow: hidden;
        }
      }
    }
  }
  .short-form {
    float: right;
    position: absolute;
    width: 40%;
    padding-bottom:60px;
    right:0;
    top:0;
    .form {
      margin-top: 30px;
      .form-group {
        .form-control {
          border: 1px solid $orange;
        }
      }
      .checkbox {
        label {
          a {
            color:white;
          }
        }
      }
    }
    h2 {
      font-family: $fontChanga;
      text-transform: uppercase;
      color: white;
      margin: 0 0 15px 0;
      padding: 0;
      font-size:2em;
    }
    p {
      color: white;
      font-weight: 100;
    }
    .form-container {
      margin-top: -200px;
      background: $orange;
      border-radius: 5px;
      max-width: 450px;
      min-width: 450px;
      display: block;
      padding: 10px;
      position: relative;
      float:left;
      width:100%;
      .form-inner {
        position: relative;
        display: block;
        border-radius: 5px;
        padding: 30px;
        border: 1px solid white;
        background: $orange;
        float:left;
        width:100%;
        .btn-primary-dark {
          width: 100%;
          display: block;
          margin: 0;
        }
        .checkbox {
          margin: 15px 0;
          label {
            color: white;
          }
        }
      }
    }
  }
}

#hoe {
  height: 650px;
  background: white url('/images/tilburg.jpg') no-repeat center right;
  .v-container,
  .v-inner {
    height: 650px;
    background:url('/images/bg_gradient.png') repeat-y top left;
    background-size:80%;
    .content{
      padding:40px 100px;
      max-width:45%;
      h2 {
        font-weight:100;
        color:black;
        margin:0 0 10px 0;
        line-height:1.6em;
        strong {
          font-weight:700;
          color:$green;
          font-size: 70px;
          line-height: 55px;
          strike {
            font-weight: 300;
            font-size: 40px;
          }
        }
      }
      p {
        font-weight:300;
        font-style:normal;
        color:$greyFont;
      }
      .btn-secondary {
        margin-top:30px;
      }
    }
  }
}

#onderdelen {
  .title {
    background:$orange;
    width:100%;
    text-align:center;
    position:relative;
    h2 {
      padding:15px 0;
      margin:0;
      color:white;
      text-transform:uppercase;
      font-size:2em;
    }
    &:after {
      content:"\f0d7";
      font-family: FontAwesome;
      position:absolute;
      color:$orange;
      bottom:-14px;
      left:0;
      right:0;
      margin:0 auto;
      font-size:40px;
      width:40px;
      height:20px;
      line-height:20px;
    }
  }
  ul {
    margin:30px 0px 45px 50px;
    padding:0;
    float:left;
    position:relative;
    list-style: none;
    li {
      width:33.333333%;
      float:left;
      padding:30px 100px 0 10px;
      position:relative;
      text-align:center;
      h3 {
        font-size:1.25em;
        margin:0;
        font-weight:400;
        font-style:normal;
        color:black;
        padding:0 0 5px 0;
      }
      p {
        color:$greyFont;
      }
      .icon-container {
        width:100%;
        text-align:center;
        img {
          display:block;
          margin:15px auto;
        }
      }
      /*
      &:before {
        content:"\f046";
        font-family: FontAwesome;
        position: absolute;
        left:45px;
        top:36px;
        font-size:36px;
        color:black;
      }*/
    }
    .icon {
      max-width:100px;
    }
  }
}

#referenties {
  background:#ebf0f4 url('/images/happy_couple.jpg') no-repeat bottom right;
  width:100%;
  height:515px;
  float:left;
  position:relative;
  #slider {
    height:515px;
    width:100%;
    display:block;
    position:relative;
    .owl-item {
      width:100%;
      height:515px;
    }
  }
  .v-container,
  .v-inner {
    position:relative;
    height:100%;
    .referentie {
      padding:0 100px;
      max-width:50%;
      text-align:center;
      p {
        font-size:2em;
        font-style:normal;
        color:$orange;
        font-weight:100;
        margin:0;
      }
    }
  }
}