footer {
  width:100%;
  float:left;
  position:relative;
  background:#f6f6f6;
  .v-container,
  .v-inner {
    height: 86px;
    width:100%;
    ul.links {
      float:left;
      margin:16px 0 0 0;
      padding:0 0 0 100px;
      list-style:none;
      li {
        color:$greyFont;
        float:left;
        margin-right:45px;
        a {
          color:$greyFont;
        }
      }
    }
    ul.logos {
      float:right;
      margin:0;
      padding:0 100px 0 0;
      list-style:none;
      li {
        float:left;
      }
    }
  }
}